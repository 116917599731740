import { FaAward, FaBookOpen, FaChild } from "react-icons/fa";
import { GiHealthNormal } from "react-icons/gi";
import { MdWork } from "react-icons/md";

export const careerContent = {
  banner: {
    button: {
      text: "Join Our Global Team",
      url: "/career/open-positions",
    },
    description: "Let's connect and explore your potential.",
    id: "1",
    imageURL: "/images/banner-images/careers.png",
    sectionTile: "",
    title: "Discover the full spectrum of iNextLabs Careers",
    model: "career",
    videoURL:
      "https://inextlabswebassets.blob.core.windows.net/video/5121002_Woman_Job_Work_1280x720.mp4",
  },
  jobItems: [
    {
      jobID: "1",
      jobTitle: "Senior Cloud Architect",
      department: "Cloud",
      imageURL: "/images/company/career/cloud.svg",
      joblocation: "Bangalore, Coimbatore, Chennai",
      jobType: "Full Time",
      JobDescription:
        "Lead the planning, technical development, testing and launch of products with a team of engineers and designers​​",
      requirements: [
        "5+ years in software development in Node.js/.NET/Java",
        "At least 2+ year’s experience in BOT framework, LUIS, QnA maker, DialogFlow, Chatbot development. Microsoft cognitive services and azure functions",
        "Significant experience with RESTful APIs, microservices, Open API’s",
        "Hands on experience in cloud infrastructure such as Azure, AWS, Heroku, etc., ",
        "Ability to handle multiple workstreams and prioritize accordingly ",
        "Comfort working with both collocated and distributed team members across time zones ",
      ],
      responsibilities: [
        "Engage with customers to define requirements, propose, and deliver complex solutions",
        "Collaborate, build consensus and work constructively with project teams across all phases of the development cycle",
        "Recommend and implement technology standards and best practices, such as CI/CD, for developing and maintaining automation solutions",
        "Leverage existing technologies, evaluate and recommend new upcoming technologies that are required",
      ],
    },
    {
      jobID: "2",
      jobTitle: "Systems Engineer",
      department: "Engineering",
      joblocation: "Bangalore, Coimbatore, Chennai",
      imageURL: "/images/company/career/system-engineer.svg",
      jobType: "Full Time",
      JobDescription:
        "Drive sales objectives and achieving commercial budget and targets.Identify and acquire new customers, as well as up-selling to the existing customer base.​​",
      requirements: [
        "At least  1 year of working experience in application and software development in Node.JS ​",
        "Familiarity with Bot development, LUIS, DialogFlow, Azure Bot Services will be an advantage​",
        "Basic understanding of Bot Technologies such as NLP, Machine Learning will be an advantage",
      ],
      responsibilities: [
        "Key responsibility will be to design and develop Conversational AI Solutions ",
        "Conversational design and simple to moderate chatbot development        ",
        "Work with the project team members and business stakeholders to discuss high-level concepts and/or desired technology solution",
      ],
    },
    {
      jobID: "3",
      jobTitle: "Azure Developer",
      department: "Cloud",
      joblocation: "Bangalore, Coimbatore, Chennai",
      imageURL: "/images/company/career/azure.svg",
      jobType: "Full Time",
      JobDescription:
        "Design, Develop and Implement AI and NLP based solutions​​",
      requirements: [
        "3+ years of working experience in application and software development​",
        "Proficiency in a modern coding languages – Node JS/Python/C#/Java​",
        "Familiarity with Bot development will be preferred​",
        "Experience with Relational Database ( SQL ) Systems. Familiarity with NoSQL is a plus.",
        "Good understanding of client-server architecture",
        "Basis understanding of frontend technologies such as HTML5, CSS3, JavaScript​",
        "Basic understanding of NLP techniques.",
      ],
      responsibilities: [
        "Key responsibility will be to design and develop enterprise applications on Azure using the modern-stack Microsoft technologies",
        "Conversational design and simple to moderate chatbot development",
        "Recommend and implement technology standards and best practices, such as CI/CD, for developing and maintaining automation solutions",
        "Work with the project team members and business stakeholders to discuss high-level concepts and/or desired technology solution",
      ],
    },
    {
      jobID: "4",
      jobTitle: "Sales Consultant",
      department: "Marketing",
      joblocation: "Singapore",
      imageURL: "/images/company/career/sales-consultant.svg",
      jobType: "Full Time",
      JobDescription:
        "Drive sales objectives and achieving commercial budget and targets.Identify and acquire new customers, as well as up-selling to the existing customer base.​​",
      requirements: [
        "Bachelor/Master degree in Sales, Marketing, Business Administration or related fi​",
        "High-level communication skills​",
        "You have a strong track record of success with prior experience in a similar role",
      ],
      responsibilities: [
        "Drive sales objectives and achieving commercial budget and targets.",
        "Identify and acquire new customers, as well as up-selling to the existing customer base",
        "Increase client & partner base",
        "Prepare proposals, and present solutions to customers",
      ],
    },
    {
      jobID: "5",
      jobTitle: "Sales Director",
      department: "Marketing",
      joblocation: "India",
      imageURL: "/images/company/career/sales.svg",
      jobType: "Full Time",
      JobDescription:
        "Drive sales objectives and achieving commercial budget and targets.Identify and acquire new customers, as well as up-selling to the existing customer base.​​",
      requirements: [
        "Bachelor/Master degree in Sales, Marketing, Business Administration or related fi​",
        "High-level communication skills​",
        "You have a strong track record of success with prior experience in a similar role",
      ],
      responsibilities: [
        "Drive sales objectives and achieving commercial budget and targets.",
        "Identify and acquire new customers, as well as up-selling to the existing customer base",
        "Increase client & partner base",
        "Prepare proposals, and present solutions to customers",
      ],
    },
    {
      jobID: "6",
      jobTitle: "Backend Developer",
      department: "Engineering",
      joblocation: "Bangalore, Coimbatore, Chennai",
      imageURL: "/images/company/career/coding.svg",
      jobType: "Full Time",
      JobDescription:
        "iNextLabs is a cloud and AI focused company specialised in building Conversational AI Messaging solutions. We are a Singapore based global company expanding faster.We are looking for smart engineers to join our crew of innovative designers and developers to build the next market defining innovative products.​",
      requirements: [
        "3+ years of working experience in application and software development.​",
        "Proficiency in a modern coding languages – Node JS.​",
        "Experience with NoSQL databases will be preferred.",
        "Good understanding of cloud technologies, preferably Microsoft Azure.",
        "Basis understanding of frontend technologies such as HTML5, CSS3, JavaScript.",
      ],
      responsibilities: [
        "Key responsibility will be to design and develop enterprise applications on Azure using the modern-stack Microsoft technologies.",
        "Conversational design and simple to moderate chatbot development.",
        "Work with the project team members and business stakeholders to discuss high-level concepts and/or desired technology solution.",
      ],
    },
    {
      jobID: "7",
      jobTitle: "Presales - Dubai",
      department: "Marketing",
      joblocation: "Dubai",
      imageURL: "/images/company/career/acquisition.svg",
      jobType: "Full Time",
      JobDescription: "​",
      requirements: [
        "Capturing technology and industry trends and providing feedback to Sales and solutions team.​",
        "To collaborate with the sales support team by acting as technical expert in customer presentations.​",
        "To determine the technical requirements to meet client goals and act as liaison between the organization's sales/business development and engineering groups.",
        "To respond to requests for information or requests for proposals from customers, supplying the technical details of proposed solutions.",
        "Face-to-face engagement (on-site support) with Customer to provide Technical and Sales Support.",
        "Emphasis on Presales role such as product presentation, project report, Proof of Concept (PoC), competitive analysis and Tender document.",
        "Being field product champion for specified product.",
        "Attending industry events, providing product demonstrations and presentations.",
        "Conducting high-level technology training sessions to customers wherever necessary.",
        "Developing and conducting technical workshops for customers and prospects.",
      ],
      responsibilities: [
        "Bachelor’s degree, MBA or Advanced Degree in Marketing or Statistics is preferred.",
        "High-level communication skills.",
        "You have a strong track record of success with prior experience in a similar role.",
        "Detail-oriented with proven ability to understand and translate high level objectives into an actionable deliverable and project plan.",
        "Hands on, demonstrable experience with a wide range of industry standard technologies.",
        "Minimum 3 years of working experience, will be advantage having operational experience in Information Technology.",
      ],
    },
    {
      jobID: "8",
      jobTitle: "Sales Manager (B2B)",
      department: "Marketing",
      joblocation: "Malaysia",
      imageURL: "/images/company/career/management-service.svg",
      jobType: "Full Time",
      JobDescription:
        "iNextLabs is a cloud and AI focused company specialised in building Conversational AI Messaging solutions. We are a Singapore based global company expanding faster.We are looking for smart engineers to join our crew of innovative designers and developers to build the next market defining innovative products.​",
      requirements: [
        "Drive sales objectives and achieving commercial budget and targets.​",
        "Identify and acquire new customers, as well as up-selling to the existing customer base.​",
        "Increase client and partner base and develop business partners ecosystem. ",
        "Handle account management matters and be the point of contact for customers. ",
        "Prepare proposals, and present solutions to customers. ",
        "Attend industry functions, such as association events and conferences, and provide feedback and information on market and creative trends. ",
        "Using knowledge of the market and competitors, identify and develop the company's unique selling propositions and differentiators. ",
        "Ensuring accurate sales forecasting and account planning. ",
        "Prepare monthly, quarterly and annual sales forecasts. ",
        "Understand the company's goal and purpose to continue to enhance the company's performance and meeting company financial KPI. ",
      ],
      responsibilities: [
        "Bachelor/master’s degree in Sales, Marketing, Business Administration, or related field.",
        "Experience in POS.",
        "Relevant experience in B2B Software sales.",
        "High-level communication skills.",
        "A strong track record of success with prior experience in a similar role.",
      ],
    },
    {
      jobID: "9",
      jobTitle: "Senior Developer",
      department: "Engineering",
      joblocation: "India",
      imageURL: "/images/company/career/professional-development.svg",
      jobType: "Full Time",
      JobDescription:
        "We are seeking an experienced and enthusiastic Senior Developer to join our team and lead the development of Generative AI Chatbots. In this role, you will have the opportunity to work on cutting-edge technology and contribute to the creation of innovative conversational agents that can generate human-like responses. You will collaborate with a team of researchers and developers to design, implement, and deploy state-of-the-art chatbot systems in a start-up culture that fosters creativity and encourages professional growth. ​",
      requirements: [
        "Lead the end-to-end development and implementation of Generative AI Chatbot systems, from inception to deployment. ​",
        "Design and architect scalable and efficient conversational models using deep learning techniques, such as recurrent neural networks (RNNs) and transformers. ​",
        "Collaborate with researchers to understand and implement natural language processing (NLP) algorithms and techniques for chatbot training and evaluation. ",
        "Develop data pre-processing pipelines to clean, transform, and augment training data for improved model performance. ",
        "Train and fine-tune generative models using large-scale datasets and advanced techniques, such as reinforcement learning and transfer learning. ",
        "Conduct rigorous testing and evaluation of chatbot systems to ensure high-quality and robust performance in real-world scenarios. ",
        "Optimize and deploy chatbot models in production environments, considering factors such as latency, scalability, and reliability.  ",
        "Stay up-to-date with the latest advancements in the field of generative AI and contribute to the team's knowledge base by researching and sharing relevant papers, techniques, and tools. ",
        "Mentor and provide technical guidance to junior developers, fostering a collaborative and learning-oriented environment. ",
      ],
      responsibilities: [
        "Bachelor's degree in Computer Science, Engineering, or a related field. A Master's degree or higher is preferred. ",
        "Overall 5-8 years of proven experience in software development, AI, or related fields, with a focus on chatbot development and AI models.",
        "Strong programming skills in languages such as Python, with proficiency in deep learning frameworks like TensorFlow or PyTorch. ",
        "Solid understanding of natural language processing (NLP) concepts, techniques, and tools. ",
      ],
    },
    {
      jobID: "10",
      jobTitle: "Software Engineer",
      department: "Engineering",
      joblocation: "Bangalore, Coimbatore, Chennai",
      imageURL: "/images/company/career/system-engineer.svg",
      jobType: "Full Time",
      JobDescription: "No of positions: 2",
      requirements: [
        "At least 2 years of working experience in application and software development in Node.JS. ​",
        "Familiarity with DialogFlow, Bot Framework, Azure Bot Services will be an advantage. ​",
        "Basic understanding of Bot Technologies such as NLP, Machine Learning will be an advantage. ",
      ],
      responsibilities: [
        "Key responsibility will be to design and develop Conversational AI Solutions. ",
        "Conversational design and simple to moderate chatbot development.",
        "Work with the project team members and business stakeholders to discuss high-level concepts and/or desired technology solution. ",
      ],
    },
    {
      jobID: "10",
      jobTitle: "Lead Salesforce Developer",
      department: "Engineering",
      joblocation: "Chennai/Bengaluru/Coimbatore",
      imageURL: "/images/company/career/system-engineer.svg",
      jobType: "Full Time",
      JobDescription:
        "The Lead Salesforce Developer will be responsible for designing, developing, and implementing advanced Salesforce solutions. This role emphasizes integrating AI capabilities, particularly Generative AI and Salesforce Einstein, to enhance our business processes and customer interactions. The ideal candidate will have a solid foundation in Salesforce development, a keen interest in AI technologies, and the ability to lead and mentor a team.",
      requirements: [
        "Bachelor’s degree in computer science, Information Technology, or a related field.",
        "2-3 years of hands-on experience in Salesforce development, with a strong understanding of Salesforce architecture and components.",
        "Knowledge and practical experience with Generative AI solutions and Salesforce Einstein, including predictive analytics and machine learning.",
        "Proficiency in Apex, Visualforce, Lightning components, and other Salesforce development tools.",
        "Strong analytical and problem-solving abilities, with a focus on delivering high-quality, data-driven solutions.",
        "Excellent verbal and written communication skills, with the ability to effectively collaborate with cross-functional teams and stakeholders.",
        "Salesforce Developer certification is preferred.",
        "Strong organizational skills, attention to detail, and the ability to manage multiple tasks and projects simultaneously.",
      ],
      responsibilities: [
        "Design, develop, and deploy customized Salesforce solutions, including Apex code, Visualforce pages, and Lightning components.",
        "Implement and optimize AI features within Salesforce, focusing on Generative AI and Salesforce Einstein for predictive analytics and machine learning.",
        "Collaborate with stakeholders to gather requirements, analyze business needs, and translate them into technical specifications and robust solutions.",
        "Provide technical leadership and mentorship to junior developers, conduct code reviews, and ensure adherence to best practices and coding standards.",
        "Oversee data integration, migration, and management within the Salesforce environment, ensuring data quality and consistency.",
        "Ensure the scalability, performance, and security of Salesforce applications through proactive monitoring and optimization.",
        "Maintain comprehensive documentation of technical designs, configurations, development processes, and troubleshooting guidelines.",
        "Stay updated with the latest Salesforce and AI technologies, tools, and best practices to continuously improve our solutions and processes.",
      ],
    },
  ],
  ourTeams: [
    {
      name: "Ajisha NV",
      designation: "Senior System Engineer",
      imageURL: "/images/company/teams/ajisha.png",
      text: "I've been with iNextLabs for over three years now, and as a Senior System Engineer, it's been quite the ride. The tech we work with is super advanced – it's like peeking into the future every day. But what I really love is the team spirit here. We're like a little work family, always helping each other out",
    },
    {
      name: "Jason Issac",
      designation: "Senior AI Engineer",
      imageURL: "/images/company/teams/jason.png",
      text: "As a Senior AI Engineer here, I've had the chance to dive deep into some fascinating projects. What I appreciate most about iNextLabs is the focus on using AI ethically – it's not just about what we can do, but what we should do. And the team? They're not just colleagues; they've become mentors, friends, and my go-to brainstorming partners",
    },
    {
      name: "Kavitha G",
      designation: "Senior Manager Operations",
      imageURL: "/images/company/teams/kavitha.png",
      text: "Working with iNextLabs has been a wonderful experience. I was heard and trusted from day 1. The communication channels here are open and frank. Also, I enjoy showing up for work everyday because of my amazing team. There is always something challenging happening and everyday is filled with new learning opportunities",
    },
    {
      name: "Nandha Kumar",
      designation: "System Engineer",
      imageURL: "/images/company/teams/nandha.png",
      text: "I started my journey as a System Engineer at iNextLabs without really knowing what to expect. It turns out, it's been a great learning adventure! The tech stuff is cool, but what makes it awesome are the friendly people here. The managers are easy to talk to, my teammates are super helpful, and we all genuinely want to help each other get better. Being on this team feels really good!",
    },
    {
      name: "Siddharth Samal",
      designation: "Senior Manager - Digital Marketing",
      imageURL: "/images/company/teams/sidharth.png",
      text: "I remember joining the team with a myriad of questions in my mind. Now, sitting here, I can confidently say that this team's passion is unmatched. Everyone here feels valued and possesses a sense of ownership over their work. We foster a highly professional culture, brimming with positive energy that you can genuinely feel at the end of each day. It's a place where you sign off not just with accomplishments, but also with fulfillment. And isn't that what we all truly seek in our careers?",
    },
  ],
  benefits: [
    {
      title: " Employee Wellness:",
      description:
        "At iNextLabs, we prioritize your well-being through initiatives such as coffee breaks, open house conversations, and monthly lunch or drinks, creating a conducive environment for you to find your zen and thrive.",
      icon: <FaChild size={20} />,
    },
    {
      title: "Education and Training:",
      description:
        "Our dedication to continuous learning is evident in our Learning Fridays and provision of Free Udemy Courses. We believe in empowering our employees with the knowledge and skills they need to excel in their roles and beyond.",
      icon: <FaBookOpen />,
    },
    {
      title: "Health Insurance:",
      description:
        "Your health is our priority. We provide comprehensive health insurance coverage, ensuring that you can focus on your work with peace of mind, knowing that your well-being is taken care of.",
      icon: <GiHealthNormal />,
    },
    {
      title: "Celebrating Achievements:",
      description:
        "Recognizing excellence is at the heart of our culture. iNextLabs celebrates outstanding contributions through various programs such as Patent and Invention Recognition, Service Recognition, and Gift Card initiatives, showcasing our commitment to acknowledging and appreciating individual and team accomplishments.",
      icon: <FaAward size={20} />,
    },
    {
      title: "Work-Life Balance:",
      description:
        " We understand the importance of maintaining a healthy work-life balance. iNextLabs supports your personal time with Paid Time Off (PTO) and holidays, allowing you to relax, recharge, and return to work with renewed energy and focus.",
      icon: <MdWork size={19} />,
    },
  ],

  meta: {
    title: "Join Our Team | Careers at iNextLabs",
    description:
      "Grab career opportunities at Inext Labs. Join a dynamic team driving innovation in AI technology. Discover our current openings and apply today!",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
