import { RiSurveyLine } from "react-icons/ri";
import {
  MdInsights,
  MdOutlineDashboardCustomize,
  MdOutlineSecurity,
} from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";

export const humanresourcesContent = {
  banner: {
    primaryButton: {
      text: "Start your free trial",
      url: "/contact/#contactform",
    },
    description:
      "As the workplace evolves, AI is becoming a crucial element in enhancing human resources management. AI is set to transform employee interactions, streamline HR processes, and boost overall satisfaction within organizations.",
    id: "1",
    imageURL: "/images/solutions/hr/banner.png",
    sectionTile: "",
    title: "Transform your employee experience with AI",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/hr/query_resolution.png",
      pointer: "/images/icons/pointer.svg",
      title: "Efficient query resolution",
      descriptionOne:
        "<strong>Instant assistance:</strong> Delivering instant responses with AI, cutting wAIt times and boosting HR efficiency.",
      descriptionTwo:
        "<strong>Automated solutions:</strong> Automate queries with AI, reducing human intervention and freeing HR for strategy.",
      descriptionThree:
        "<strong>Centralized knowledge:</strong> Centralizing company policies to deliver accurate, timely responses to employees.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/solutions/hr/onboarding_processes.png",
      pointer: "/images/icons/pointer.svg",
      title: "Streamlined onboarding processes",
      descriptionOne:
        "<strong>Automated onboarding:</strong> Automate onboarding tasks like document submission and trAIning for a smoother experience.",
      descriptionTwo:
        "<strong>Personalized trAIning:</strong> Customize trAIning programs for new hires, aligning with roles and boosting productivity.",
      descriptionThree:
        "<strong>Policy guidance:</strong> Address policy-related queries instantly, ensuring new employees get essential information from day one.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/hr/service_management.png",
      pointer: "/images/icons/pointer.svg",
      title: "Enhanced HR service management",
      descriptionOne:
        "<strong>Efficient ticketing:</strong> Streamline ticket creation and management with AI, boosting HR efficiency and response times.",
      descriptionTwo:
        "<strong>Data-driven insights:</strong> Leverage AI to analyze employee data, gAIning insights for improved HR decisions.",
      descriptionThree:
        "<strong>Scalable solutions:</strong> Harness AI to efficiently manage extensive HR operations and meet organizational needs.",
    },
  ],
  stats: {
    title: "Experience the impact of AI in hr",
    description:
      "Transform your HR operations with our AI-driven solutions, boosting efficiency and creating value across the employee lifecycle.",
    content: [
      {
        id: "1",
        title: "CAGR growth rate",
        count: 20,
      },
      {
        id: "2",
        title: "Organizations using automation",
        count: 25,
      },
      {
        id: "3",
        title: "AI boosts recruitment",
        count: 67,
      },
      {
        id: "4",
        title: "Productivity increase",
        count: 42,
      },
    ],
  },
  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Unmatched security",
        tagline:
          "We ensure robust encryption, compliance, and continuous data monitoring.",
        icon: <MdOutlineSecurity size={20} />,
      },
      {
        id: "2",
        title: "Seamless integration",
        tagline:
          "Easily integrates with HR systems, offers quick deployment, and simplicity.",
        icon: <MdOutlineDashboardCustomize size={20} />,
      },
      {
        id: "3",
        title: "Data-driven insights",
        tagline:
          "Our AI solutions provide real-time analytics for informed, data-driven HR decisions.",
        icon: <MdInsights size={20} />,
      },
      {
        id: "4",
        title: "Comprehensive support",
        tagline:
          "Enhance communication with humanized, multi-channel, multilingual support for teams.",
        icon: <VscFeedback size={20} />,
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "What is AI in HR?",
      answer:
        "AI in HR automates and simplifies tasks such as managing inquiries, onboarding, and other HR functions. By leveraging natural language processing, AI enhances efficiency and improves employee experiences.",
    },
    {
      id: "2",
      question: "How is generative AI utilized in HR?",
      answer:
        "Generative AI in HR provides personalized trAIning, swift knowledge responses, predictive analytics for recruitment and retention, automated resume screening, and customized communication. It boosts efficiency, reduces bias, and enhances the overall employee experience.",
    },
    {
      id: "3",
      question: "Why is AI crucial for your HR department?",
      answer:
        "AI plays a crucial role in HR by enhancing functions across the employee lifecycle, including recruitment, onboarding, development, and talent management. As this industry evolves with changing employee expectations, AI prompts a reevaluation of HR roles and team structures.",
    },
    {
      id: "4",
      question: "What does the future hold for AI and human resources?",
      answer:
        "In the coming years, AI will streamline resource-intensive processes, eliminate repetitive tasks, and AId in creating HR content and documentation. Generative AI is expected to advance in natural language processing, impacting areas like virtual assistants and unstructured data analysis.",
    },
  ],
  meta: {
    title: "AI Solutions for Human Resources - iNextLabs",
    description:
      "A smart solution designed to transform your HR experience with an AI assistant that specializes in instant query resolution, process streamlining, and enhanced employee satisfaction.",
    locale: "en_us",
    siteName: "inextlabs",
    image: "https://www.inextlabs.com/images/general/hr_resources.png",
    type: "website",
  },
};