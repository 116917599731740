import { FaHandsHelping } from "react-icons/fa";
import {
  MdOutlineSecurity,
  MdInsights,
  MdIntegrationInstructions,
} from "react-icons/md";
export const aiAssistantsContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Our AI Assistants, built on state-of-the-art foundation models, are secure, reliable, and rigorously tested to meet diverse business needs. Available in chat, voice, and avatar mediums, and integrated across 40+ channels, they support a wide range of functions, from IT and HR to Customer Support, Sales, and beyond. Operating around the clock and speaking multiple languages, they handle routine tasks, provide valuable insights, suggest improvements, gauge sentiments, and seamlessly integrate with other systems, enhancing operational accuracy and security across various industries. ",
    imageURL: "/images/products/ai-assistants/banner.jpg",
    sectionTile: "",
    title: "Boost your business efficiency with AI assistants ",
  },

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/products/ai-assistants/drive-business-outcomes.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Drive business outcomes with every conversation ",
      mainDescription:
        "Our AI assistants go beyond answering questions—they create interactions that drive real business results. From customer engagement to workflow optimization, every conversation is goal oriented. With our assistants, you get more than just a chatbot; you get a strategic tool that turns interactions into actionable outcomes.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/products/ai-assistants/accuracy-you-can-trust.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Accuracy you can trust",
      mainDescription:
        "We know the importance of accurate information, which is why our assistants are built to eliminate data fabrication or hallucinations. You can rely on them to provide precise, trustworthy responses every time. Our AI assistants are designed with rigorous testing and validation processes, ensuring they meet the highest standards of reliability. With our assistants, you get dependable answers, not guesswork—because your business deserves the best.",
    },
    {
      id: "3",
      model: "model1",
      imageURL:
        "/images/products/ai-assistants/seamless-integration-automation.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Seamless integration and automation",
      mainDescription:
        "Our AI assistants are designed to work with any system you use, be it CRM, ERP, or other business tools. They not only integrate effortlessly but also automate repetitive tasks, freeing up your team to focus on what really matters. Whether it's updating records, scheduling meetings, or processing requests, our Assistants handle it all with ease. Experience the power of seamless automation that fits right into your existing workflow. ",
    },
    {
      id: "4",
      model: "model1",
      imageURL:
        "/images/products/ai-assistants/partnered-with-industry-leaders.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Partnered with industry leaders",
      mainDescription:
        "We bring the best in AI technology by partnering with industry leaders. Our commitment to staying at the forefront of innovation means you always get the latest and most advanced solutions. With our assistants, you’re not just adopting AI—you’re aligning with a future-ready partner who’s dedicated to your success. Trust in our expertise and connections to deliver cutting-edge capabilities that keep your business ahead of the curve.",
    },
  ],
  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Unmatched security",
        tagline:
          "We ensure robust encryption, compliance, and continuous data monitoring.",
        icon: <MdOutlineSecurity size={20} />, // Icon representing templates
      },
      {
        id: "2",
        title: "Seamless integration",
        tagline:
          "Easily integrates with IT systems, offering quick deployment, and simplicity.",
        icon: <MdIntegrationInstructions size={20} />, // Icon representing WhatsApp
      },
      {
        id: "3",
        title: "Data-Driven insights",
        tagline:
          "Our AI solutions provide real-time analytics for informed, data-driven decisions.",
        icon: <MdInsights size={20} />, // Icon representing multi-channel integration
      },
      {
        id: "4",
        title: "Comprehensive support",
        tagline:
          "Enhance communication with humanized, multi-channel, multilingual support for teams.",
        icon: <FaHandsHelping size={20} />, // Icon representing AI assistance
      },
    ],
  },
 
  FAQ: [
    {
      id: "1",
      question:
        "1.What are AI Assistants, and how do they differ from traditional chatbots? ",
      answer:
        "Our AI assistants are advanced AI-powered virtual assistants that go beyond simple Q&A. They drive business outcomes through engaging interactions, workflow automation, and continuous learning from various data sources, making them more effective and versatile than traditional chatbots. ",
    },
    {
      id: "2",
      question: "2.Can AI assistants be deployed on any cloud or on-premises? ",
      answer:
        "Yes, our AI assistants offer flexible deployment options to suit your needs. They can be deployed on any major cloud platform, including AWS, Azure, and Google Cloud, or even on-premises, ensuring that you have the right deployment environment for your business requirements. ",
    },
    {
      id: "3",
      question:
        "3. Are AI assistants secure, and do they comply with data protection regulations? ",
      answer:
        "Absolutely. Security and compliance are core features of our AI assistants. They use the latest encryption standards and adhere to industry regulations, ensuring your data remains secure and protected at all times. ",
    },
    {
      id: "4",
      question: "4. What channels are supported by AI assistants?",
      answer:
        "Our AI assistants support a wide range of communication channels, including voice, avatars, and text-based platforms such as social media, web chat, and messaging apps. This versatility ensures that your customers can engage with your business through their preferred channels, enhancing accessibility and convenience. ",
    },
  ],
  stats: {
    title: "AI assistants that deliver real business value ",
    description: "Cut wait times, streamline tasks, and enhance response speed",
    content: [
      {
        id: "1",
        title: "routine customer inquiries handled",
        count: 80, // Since this is a percentage, you may not have a fixed count.
      },
      {
        id: "2",
        title: "decrease in IT resolution times",
        count: 50, // Similarly, this represents a percentage.
      },
      {
        id: "3",
        title: "Improved response time",
        count: 45,
      },
      {
        id: "4",
        title: "Reduced wait times. for customers",
        count: 50,
      },
    ],
  },

  meta: {
    title: "AI-Powered Assistants | Enhance Business Efficiency Across Channels",
    description:
      "Boost efficiency with AI-powered virtual assistants, AI voicebots, and AI Avatars. Secure support across 40+ channels for IT, HR, Customer Support, and Sales.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
